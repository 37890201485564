/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:56f1a07f-31aa-4270-9a53-30f72b2dcf5e",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_elAlCQLYV",
    "aws_user_pools_web_client_id": "68lbi95p1p2ht8ar7kl033rs3l",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wlyia7kigzaqhakwfiwkarmh5i.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
